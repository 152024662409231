// Hotdesk States
// --------------------------------
@keyframes hotdesk_speaking
    0%
        transform: scale(1)

    50%
        transform: scale(1.2)

    100%
        transform: scale(1)

@keyframes hotdesk_ringing
    0%
        transform: rotate(0deg)

    25%
        transform: rotate(-15deg)

    50%
        transform: rotate(0deg)

    75%
        transform: rotate(+15deg)

    100%
        transform: rotate(0deg)

@keyframes hotdesk_ringing_shake
    10%, 90%
        transform: translate3d(-1px, 0, 0)

    20%, 80%
        transform: translate3d(2px, 0, 0)

    30%, 50%, 70%
        transform: translate3d(-3px, 0, 0)

    40%, 60%
        transform: translate3d(3px, 0, 0)

.hotdesk-state
    font-family: FontAwesome
    color: $body-color
    display: inline-block
    font-style: normal
    font-weight: normal
    width: 2rem
    text-align: center

    &::before
        content: "\f00d"

    &.hotdesk-state-UNKNOWN::before
        content: ""
    &.hotdesk-state-UNKNOWN
        width: 0

    &.hotdesk-state-UNAVAILABLE::before
        content: "\f00d"
        color: $red

    &.hotdesk-state-UNREACHABLE::before
        content: "\f00d"
        color: $red

    &.hotdesk-state-RINGING::before
        content: "\f0f3"
        color: $yellow

    &.hotdesk-state-RINGING
        animation-name: hotdesk_ringing
        animation-duration: 250ms
        animation-iteration-count: infinite
        animation-timing-function: linear

    &.hotdesk-state-INUSE
        animation-name: hotdesk_speaking
        animation-duration: 1s
        animation-iteration-count: infinite
        animation-timing-function: linear

    &.hotdesk-state-INUSE::before
        content: "\f2a0"
        color: $blue

    &.hotdesk-state-NOT_INUSE::before
        content: "\f095"
        color: $green
