@import "bootstrap.variables"
@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins"

@import "bootstrap.config"

$fa-font-path: "~font-awesome/fonts"
@import "~font-awesome/scss/font-awesome"

@import "~select2/src/scss/core"
@import "~bootstrap-vue/dist/bootstrap-vue.css"

@import hotdesk_state_icons

html
    font-size: 14px

body
    // add top padding to fix overlapping fixed navbar
    padding-top: $navbar-brand-height * 1.5 * 2

// Responsive images
// --------------------------------
img
    max-width: 100%
    height: auto

// Lists
// --------------------------------
.dl-horizontal
    @include make-row()

    dt
        @include make-col(4)
        padding-left: 1rem

    dd
        @include make-col(8)

// Cards
// --------------------------------
.card
    margin-bottom: 2rem

// Buttons
// --------------------------------
.btn-block-left
    text-align: left

    &.dropdown-toggle
        padding-right: 2.5rem !important

        &::after
            position: absolute
            top: 45%
            right: 1rem

.row-striped-bg
    .row:nth-child(even)
        background-color: transparent
    .row:nth-child(odd)
        background-color: $gray-200

// Navbar
// --------------------------------
.navbar-right
    padding-right: 15px

.navbar img
    height: $navbar-brand-height

.navbar-nav > li > a.user-menu
    padding-top: 0
    padding-bottom: 0

.user-menu > i
    font-size: 1rem

// Body
// --------------------------------
body
    // fixed navigation header height
    overflow-y: scroll

// Select2 width fix
// --------------------------------
.select2-container
    width: 100%

// Dropbdown Buttons
// --------------------------------
// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item
    display: block
    width: 100%

    // For `<button>`s
    padding: 5px 5px
    clear: both
    color: $dropdown-link-color
    text-align: inherit

    // For `<button>`s
    white-space: nowrap

    // prevent links from randomly breaking onto new lines
    background: none

    // For `<button>`s
    border: 0

    // For `<button>`s

    &:hover, &:focus
        color: $dropdown-link-hover-color
        text-decoration: none
        background-color: $dropdown-link-hover-bg

    &.active,
    &:active
        color: $dropdown-link-active-color
        text-decoration: none
        background-color: $dropdown-link-active-bg

    &.disabled,
    &:disabled
        color: $dropdown-link-disabled-color
        background-color: transparent

// VueJS Autocomplete Dropdown Menu
// --------------------------------
.autosuggest__results
    ul
        @extend .dropdown-menu
        display: block
    li
        @extend .dropdown-item

// Badges
// --------------------------------
.badge-danger
    background-color: #d9534f

// Blur text; Unblur on hover
// --------------------------------
.unshadow-text-on-hover
    color: transparent
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5)

    &:hover
        color: $body-color
        text-shadow: none

// Cursor
// --------------------------------
*[data-toggle="collapse"]
    cursor: pointer

// Collapse toggle icon
// --------------------------------
*[data-toggle="collapse"]
    .collapse-toggle-icon
        font-family: FontAwesome
        color: $body-color
        display: inline-block
        float: right
        font-style: normal
        font-weight: normal
        width: 2rem
        text-align: center

        &::before
            content: "\F077"
    &.collapsed
        .collapse-toggle-icon
            &::before
                content: "\F078"


// Barcode Scanner
// --------------------------------
.barcode-scanner-viewport
    position: relative

    video, canvas
        max-width: 100%
        width: 100%

    .drawingBuffer
        position: absolute
        left: 0
        top: 0
